﻿.group-details-form {
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
}

.tabs-container {
    background-color: $tabs-background-color;
    margin-bottom: 15px;
    a {
        color: #9fa1a3;

        &:hover {
            color: #3f393f;
        }
    }
}

.location-text {
    padding: 6px 0;
}

.blank-area-height-group-details{
    height: 450px;
}

.blank-area.blank-area-height-group-details {

    img {
        width: 10%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    h4, p {
        width: 50%;
        margin: 5px auto;
    }
}
