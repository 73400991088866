.p-picklist-list-wrapper {
    height: 394px;
    overflow-y: auto;
    border-radius: unset;
    width: 100%;
}


.p-picklist-list {
  max-height: unset;
}

.p-picklist-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-picklist-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid $background-light-grey;
    transition: background 0.2s ease;
    font-family: RobotoRegular, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: $dual-list-transfer-button-color;

    &:focus:not(.active),
    &:hover:not(.active),
    &.p-focus:not(.active) {
        background-color: $active-nav-border-light-color;
        outline: none;
    }

    &.p-highlight {
        background-color: $active-nav-bg;
        border: 1px solid $active-nav-border-color;
    }
}

.p-picklist-transfer-buttons {
  display: grid;
  margin: 15px;
}

.p-picklist-transfer-buttons .p-button:nth-child(1) {
    grid-row: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 1.5px;
}

.p-picklist-transfer-buttons .p-button:nth-child(2) {
    grid-row: 3;
    border-radius: 0;
    margin-bottom: 1.5px;
}

.p-picklist-transfer-buttons .p-button:nth-child(3) {
    grid-row: 2;
    margin-bottom: 4.5rem;
    border-radius: 0;
}

.p-picklist-transfer-buttons .p-button:nth-child(4) {
    grid-row: 4;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-picklist {
  display: flex;
  justify-content: center; 
  align-items: center;
}

.p-picklist-transfer-buttons .p-button {
    display: block;
    float: none;
    color: $dual-list-transfer-button-color;
    background-color: $dual-list-transfer-button-background-color;
    border-color: $dual-list-transfer-button-border-color;
    width: 39px;
    height: 33px;

    &.p-disabled {
        cursor: not-allowed;
        background-color: $dual-list-transfer-button-background-color-disabled;
    }

    &:enabled {
        background-color: $dual-list-transfer-button-background-color-enabled;
    }

    &:hover, 
    &:focus {
        background-color: $dual-list-transfer-button-background-color-hovered;
    }

    .p-icon {
        all: unset;
        justify-content: center;
    }
}

@media screen and (max-width: 768px){

    .p-picklist-transfer-buttons {
        display: flex;

        .p-button:nth-child(1) {
            order: 1;
            border-radius: 4px;
        }

        .p-button:nth-child(2) {
            order: 3;
            border-radius: 4px;
        }

        .p-button:nth-child(3) {
            order: 2;
            border-radius: 4px;
        }

        .p-button:nth-child(4) {
            border-radius: 4px;
            order: 4;
        }
    }

}