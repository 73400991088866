.p-inputtextarea {
  &.p-inputtext {
    background-color: $background-light-grey;
    border-color: $background-light-grey;
    border-radius: 0;

    &:focus {
      outline: 0;
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.has-error {
  .p-inputtextarea {
    &.p-inputtext {
      border-color: $validation-error-color;

      &:focus {
        box-shadow: $input-error-box-shadow;
      }
    }
  }
}