﻿.custom-tooltip-container {
    text-align: center;
    max-width: 190px;
}

.custom-tooltip-arrow {
    scale: 3;
    margin-bottom: 5px;
}

.custom-tooltip-text {
    font-family: $font-family-sans-serif;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
}
